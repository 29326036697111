import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import AddProductsComponent from "../../components/products/addProduct";
import MobileNavigation from "../../layouts/mobileNavigation";



const AddProductsView = () => (
  <Container fluid className="main-content-container px-4">
    <MobileNavigation />
    <Row noGutters className="page-header py-4">
      <PageTitle title="Product toevoegen" subtitle="Beter bij ons" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>
    <AddProductsComponent />
    
  </Container>
);

export default AddProductsView;
