import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { StaticRouter } from 'react-router';
import { set } from 'react-ga';
import { Satellite } from '@material-ui/icons';
import toast from 'react-hot-toast';


class DayPricesComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            products:[],
            categories:[],
            selectedProduct:[],
            isProductSelected:false,
            clientUUID:'-ohlnfwebk',
            city:''
        };
    }

    getProducts(){
    
        let ref = Firebase.database().ref('/cities/-onefoefw/shops/'+Firebase.auth().currentUser.uid+'/products');
        ref.on('value' , snapshot => {
            this.setState({products:[]})
           
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();
            console.log("resources:"+state);
            if(state.activeVariations == false){
                this.setState(prevState => ({
                    products: [...prevState.products, state]
                })) 
            }else{
                for(let i= 0; i < state.variations.length; i++){
                    let newProduct = {activeVariations: state.activeVariations,btwValue: state.btwValue, id:state.id, name:state.name,variantName:  state.variations[i].name, price: Number(state.variations[i].price), stock: state.variations[i].stock}
                    this.setState(prevState => ({
                        products: [...prevState.products, newProduct]
                    })) 

                }
                
            }
           

            
                

            
                console.log(state.categories)

                let cats = Firebase.database().ref('/cities/-onefoefw/shops/'+Firebase.auth().currentUser.uid+'/products/'+state.id+'/categories');
                cats.on('value' , snapshot => {
            
                        snapshot.forEach((childSnap) => {
                        let stateFac = childSnap.val();
                        
                        let newCat = {catId:state.id, id:stateFac.id,  name:stateFac.name}
                            this.setState(prevState => ({
                                categories: [...prevState.categories, newCat],
                            }));
                    });
                    
                    })

               

                

        })
    });
    }

    getProductsAPI(){
        fetch('https://c8ea95b3eff5a6b39696be0f5d52e9bc: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@koffie-anders.myshopify.com/admin/api/2021-07/vendors.json')
        .then((response) => {
          return response.json(); // or .text() or .blob() ...
        })
        .then((text) => {
          // text is the response body
        })
        .catch((e) => {
          // error in e.message
          console.log(e.message)
        });

    }



    componentDidMount() {
        console.log(Firebase.auth().currentUser.uid)
        this.setState({
            clientUUID: Firebase.auth().currentUser.uid
        })

      this.getProductsAPI();
     

        let ref = Firebase.database().ref('/users/'+Firebase.auth().currentUser.uid);
        ref.on('value' , snapshot => {
           
   
            let state = snapshot.val();
             
                this.getProducts();
            
        });
      

        
    }

    changeActive(product){

        for(let k = 0; k <this.state.products.length; k++){
                this.state.products[k].checked = false;
                this.state.products[k].active = 'not-active';
                
                this.setState({
                    products: this.state.products,
                    selectedProduct: [],
                    isProductSelected:false
                })
               
             
        }

        for(let i = 0; i < this.state.products.length; i++){
            if(this.state.products[i].id === product.id && this.state.products[i].checked === false){
                this.state.products[i].active = 'active';
                this.state.products[i].checked = true;
                this.setState({
                    products: this.state.products,
                    selectedProduct: this.state.products[i],
                    isProductSelected:true
                })
            }else if(this.state.products[i].id === product.id && this.state.products[i].checked === true){
                this.state.products[i].active = 'not-active';
                this.state.products[i].checked = false;
                this.setState({
                    products: this.state.products,
                })
            }
        }


      
     
        
       /* this.setState({
            products:this.state.products.filter(item => item.id !== product.id),
        })

        let newProduct = {id:product.id,name:product.name, status:product.status,stock: product.stock,category:product.category,active:'active'}
        this.setState(prevState =>({
            products: [...prevState.products,newProduct]
        }))*/
    }


    deleteProduct(){
        Firebase.database().ref('/cities/-onefoefw/shops/'+Firebase.auth().currentUser.uid+'/products/'+this.state.selectedProduct.id).remove();
        this.setState({
            products:this.state.products.filter(item => item.id !== this.state.selectedProduct.id),
            selectedProduct:[],
            isProductSelected:false
        });

        
    }

    changeStock(product,stock){

    if(product.activeVariations == false){
        for(let i = 0; i < this.state.products.length; i++){
            if(this.state.products[i].id === product.id ){
                this.state.products[i].stock = Number(stock);
            
                this.setState({
                    products: this.state.products,
                 
                })
            }
        }
    } else{
        
        for(let i = 0; i < this.state.products.length; i++){
            if(this.state.products[i].id === product.id && product.variantName == this.state.products[i].variantName ){
                this.state.products[i].stock = Number(stock);
            console.log('change variant stock')
                this.setState({
                    products: this.state.products,
                 
                })
            }
        }
    }
       
    }

    changePrice(product,price){
        if(product.activeVariations == false){
        for(let i = 0; i < this.state.products.length; i++){
            if(this.state.products[i].id === product.id ){
                this.state.products[i].price = Number(price);
            
                this.setState({
                    products: this.state.products,
                 
                })
            }
        }
    }else{
        for(let i = 0; i < this.state.products.length; i++){
            if(this.state.products[i].id === product.id && product.variantName == this.state.products[i].variantName ){
                this.state.products[i].price = Number(price);
            
                this.setState({
                    products: this.state.products,
                 
                })
            }
        }
    }
        
    }


    updateProducts(){
        this.state.products.map((product)=>{
            if(product.activeVariations ==false){
                Firebase.database().ref('/cities/-onefoefw/shops/'+Firebase.auth().currentUser.uid+'/products/'+product.id).update({
                    price:product.price,
                    stock:product.stock
                }).then(()=>{
                    toast.success('producten opgeslagen')
                })
            }else{
                let ref = Firebase.database().ref('/cities/-onefoefw/shops/'+Firebase.auth().currentUser.uid+'/products');
                ref.once('value' , snapshot => {
                    this.setState({products:[]})
                   
                    snapshot.forEach((childSnap) => {
                    let state = childSnap.val();
                    if(state.activeVariations == true){
                        for(let i= 0; i < state.variations.length; i++){
                            if(state.variations[i].name == product.variantName){
                                Firebase.database().ref('/cities/-onefoefw/shops//'+Firebase.auth().currentUser.uid+'/products/'+product.id+'/variations/'+[i]).update({
                                    price:product.price,
                                    stock:product.stock
                                }).then(()=>{
                                    toast.success('producten opgeslagen')
                                })
                            }

                        }
                    }
                    })
                })
                
                
            }
            
        })
        toast.success('producten opgeslagen')
    }
    

    render() {
        return (
            <div>
            <Row className="col-sm-12">
            <Col sm={12}>
        <input type="text" placeholder="Zoeken..." id="icon" className="search-bar" value={this.state.searchItem} onChange={(event)=>this.setState({searchItem:event.target.value})} />
        </Col>
                <Col sm={12} className="products-actions">
                    {this.state.isProductSelected ?
                    <div>
                    <Link to={{pathname: `/producten/aanpassen`,state: {data: this.state.selectedProduct}}} >Product bewerken</Link>
                    <Button className="delete-button" onClick={() => this.deleteProduct()}>Verwijder product</Button></div>:null}
                    <Button className="align-button-right" onClick={() => this.updateProducts()}>Opslaan</Button>
                </Col>
             
        {this.state.searchItem ?
        <Col sm={12} className="products-table">
                <h3>Zoek resultaten</h3>
                <table>
                <tr>
                    <th></th>
                    <th>Productnaam</th>
                    <th>Status</th>
                    <th>Voorraad</th>
                    <th>Prijs</th>
                </tr>

                {this.state.products.filter((item) => this.state.searchItem ?  item.name.toLowerCase().includes(this.state.searchItem.toLowerCase()):item.name == item.name).map((product)=>(
                    <tr className={product.active}>
                    <td><input type="checkbox" checked={product.checked} onClick={()=>this.changeActive(product)}/></td>
                    <td>{product.name}</td>
                    <td>{product.status}</td>
                    <td><input type="text" placeholder={product.stock} onChange={(event)=> this.changeStock(product,event.target.value)}/></td>
                    <td><input type="text" placeholder={product.price.toFixed(2)} onChange={(event)=> this.changePrice(product,event.target.value)}/></td>
                </tr>
                ))}
                
            
            </table>
</Col>:null} 
{this.state.searchItem ? null :
                <Col sm={12} className="products-table">
                       <h3>Alle producten</h3>
            <table>
                <tr>
                    <th></th>
                    <th>Productnaam</th>
                    <th>Status</th>
                    <th>Voorraad</th>
                    <th>Prijs</th>
                </tr>

                {this.state.products.map((product)=>(
                    <tr className={product.active}>
                    <td><input type="checkbox" checked={product.checked} onClick={()=>this.changeActive(product)}/></td>
                    <td>{product.name} - {product.activeVariations ? product.variantName :null}</td>
                    <td>{product.status}</td>
                    <td><input type="text" placeholder={product.stock} onChange={(event)=> this.changeStock(product,event.target.value)}/></td>
                    <td><input type="text" placeholder={product.price.toFixed(2)} onChange={(event)=> this.changePrice(product,event.target.value)}/></td>
                    </tr>
                ))}
                
            
            </table>
</Col>}
   
            </Row>
            </div>



        );
    }
};

export default DayPricesComponent;