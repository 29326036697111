import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { keys } from '@material-ui/core/styles/createBreakpoints';
import toast from 'react-hot-toast';


class MessageIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            messages:[],
            clientUUID:'-ohlnfwebk',
            dayMessage:''

            
        };
    }

    getMessages(city){


        let ref = Firebase.database().ref('/stand-alone/'+Firebase.auth().currentUser.uid);
        ref.on('value' , snapshot => {
            let state = snapshot.val();
           
            this.setState({
                dayMessage: state.dayMessage
            })
        });
    }



    componentDidMount() {
        this.setState({
            clientUUID: Firebase.auth().currentUser.uid
        })
        let ref = Firebase.database().ref('/users/'+Firebase.auth().currentUser.uid);
        ref.on('value' , snapshot => {
           
   
            let state = snapshot.val();
              
                this.getMessages();
               
            
        });
     
    }



    changeNew(id){

        Firebase.database().ref('/cities/-onefoefw/shops/'+Firebase.auth().currentUser.uid+'/messages/'+id).update({
            new: false,
        }).then((data)=>{

        }).catch((error)=>{
            //error callback
            console.log('error ' , error)
        })

    }

    saveText(){
        Firebase.database().ref('/stand-alone/'+Firebase.auth().currentUser.uid).update({
            dayMessage: this.state.dayMessage,
        
        }).then(()=>
        toast.success('Tekst is bijgewerkt'))
    }
    

    render() {
        return (
            <div>
            <Row>
                        <Col sm={12} className="add-product-colum">
                            <label>Bericht</label><br/>
                            <input type="text" value="" value={this.state.dayMessage} onChange={(event)=>this.setState({dayMessage: event.target.value, error:false,changes:true})}/><br/><br/>
                            <Button onClick={()=>this.saveText()}>Sla tekst op</Button>
                           
                        </Col>
                       
                    </Row>
            </div>



        );
    }
};

export default MessageIndex;