import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import 'react-activity/dist/react-activity.css';

import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import Firebase from 'firebase';




class OrderPostDetailComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status:'',
            payment:[],
            price:0,
            invoicesUrl:'',
            items:[],
            producenten:[]
      };
    }

    componentDidMount(){


       this.setState({
           items:JSON.parse(this.props.billData.billData.items),
       })
       for(let i =0; i < JSON.parse(this.props.billData.billData.items).length; i++){

        let storeInfo = Firebase.database().ref('/cities/-onefoefw/shops/'+JSON.parse(this.props.billData.billData.items)[i].winkelier);
        storeInfo.once('value' , snapshot => {
            let state = snapshot.val();

            let producent = {itemId:JSON.parse(this.props.billData.billData.items)[i].id, shopName:state.companyName}
            this.setState(prevState => ({
                producenten: [...prevState.producenten, producent]
            }))  
            console.log(state.companyName)

        })
       }
        let payments = Firebase.database().ref('/cities/-onefoefw/orders/'+this.props.billData.billData.id);
        payments.on('value' , snapshot => {
            
            let state = snapshot.val();
            
            this.setState({
                payment: state
            })
            Firebase.storage().ref(state.invoiceUrl).getDownloadURL().then((url) => {
                console.log("factuur:"+url)
                    this.setState({
                        invoicesUrl:url
                    })
              })
        });

    
       // this.calculatePriceTotal();
        this.checkStatus();



    }




    checkStatus(){
        if(this.props.billData.billData.status === 'done'){
            this.setState({
                status: 'Afgehandeld'
            })
        }else if(this.props.billData.billData.status === 'in progress'){
            this.setState({
                status: 'In afwachting'
            })
        }else if(this.props.billData.billData.status === 'cancelled'){
            this.setState({
                status:'Geannuleerd'
            })
        }else if(this.props.billData.billData.status === 'In afwachting'){
            this.setState({
                status: 'In afwachting'
            })
        }else if(this.props.billData.billData.status === 'Afgehandeld'){
            this.setState({
                status: 'Afgehandeld'
            })
        }else if(this.props.billData.billData.status === 'Geannuleerd'){
            this.setState({
                status: 'Geannuleerd'
            })
        }
    }
    calculatePriceTotal(){

let newPrice =[];

       for(let i=0; i< this.props.billData.billData.items.length; i++){
        if(this.props.billData.billData.items[i].winkelier == Firebase.auth().currentUser.uid){
            let totalItemPrice =this.props.billData.billData.items[i].price *this.props.billData.billData.items[i].amount
         
           newPrice.push(totalItemPrice)
           
           
        }
       }

    let countendPrice = newPrice.reduce((a, b) => a + b, 0);
           
        this.setState({
            price: newPrice.reduce((a, b) => a + b, 0),
        
     
        
    

        })
    }
    

    testFunction(){
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        
       headers.append('Origin','https://app.beterbijons.nl/')
        fetch("https://us-central1-web-app-bbo-prod.cloudfunctions.net/NotificationOnOrderComplete", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({email: this.props.billData.billData.email,winkelier:'Demo winkel'})
          })
          .then(res => {
              console.log(res)
            
            return res.json();
          })
          .then(data => {
   
 
          });
    }


   
    changeStatus(event){
        if(event === 'Afgehandeld'){

            
            Firebase.database().ref('/cities/-onefoefw/orders/'+this.props.billData.billData.id).update({
               newOrder:false,
               status:'done'
    
    
        
            }).then((data)=>{
    this.setState({
        status:event
    })


               
    toast.success('Bestelling is afgehandeld');
            }).catch((error)=>{
                //error callback
                console.log('error ' , error)
            })
        }else if(event === 'In afwachting'){
            Firebase.database().ref('/cities/-onefoefw/orders/'+this.props.billData.billData.id).update({
                newOrder:true,
                status:'in progress'
     
     
         
             }).then((data)=>{
                this.setState({
                    status:event
                })
                toast.success('Status veranderd naar lopende bestelling');
     
                
     
             }).catch((error)=>{
                 //error callback
                 console.log('error ' , error)
             })
        }
        else if(event === 'Geannuleerd'){
            Firebase.database().ref('/cities/-onefoefw/orders/'+this.props.billData.billData.id).update({
                newOrder:false,
                status:'cancelled'
     
     
         
             }).then((data)=>{
                this.setState({
                    status:event
                })
                toast.success('Status veranderd naar lopende bestelling');
     
                
     
             }).catch((error)=>{
                 //error callback
                 console.log('error ' , error)
             })
        }
        else if(event === 'In behandeling'){
            Firebase.database().ref('/cities/-onefoefw/orders/'+this.props.billData.billData.id).update({
                newOrder:false,
                status:'In progress'
     
     
         
             }).then((data)=>{
                this.setState({
                    status:event
                })
                toast.success('Status veranderd naar inbehandeling ');
     
                
     
             }).catch((error)=>{
                 //error callback
                 console.log('error ' , error)
             })
        }
    }

    render() {  

        
        return (
            <div>
         <Toaster/>
                <Row >
                <Col sm={12}>
 <Link className="back-link mobile-back-link" to={{pathname: `/post`}} >Terug</Link>
 </Col>
                    <Col sm={6} className="bill-detail-list">
                        <ul>
                            <li>Ordernummer:<span className="bill-detail-list-element">{this.props.billData.billData.orderID}</span></li>
                            <li>Naam:<span className="bill-detail-list-element">{this.props.billData.billData.name}</span></li>
                            <li>E-mail:<span className="bill-detail-list-element">{this.props.billData.billData.email}</span></li>
                            <li>Telefoon:<span className="bill-detail-list-element">{this.props.billData.billData.phone}</span></li>
                            <li>Adres:<span className="bill-detail-list-element">{this.props.billData.billData.street}, {this.props.billData.billData.city}</span></li>
                            
                            <li>Provincie:<span className="bill-detail-list-element">{this.props.billData.billData.provincie}</span></li>
                           {/*} <li>Afleverdatum:<span className="bill-detail-list-element">{new Date(this.state.payment.date).getDate()}-{new Date(this.state.payment.date).getMonth()+1}-{new Date(this.state.payment.date).getFullYear()}</span></li>
                            <li>Tijdslot:<span className="bill-detail-list-element">{this.state.payment.timeslot}</span></li>
        <li>Totaal:<span className="bill-detail-list-element"> € {parseFloat(this.state.price).toFixed(2)}</span></li>*/}
     {/*this.state.status == 'Afgehandeld'? <li>Factuur:<span className="bill-detail-list-element"> <a target="_blank" href={this.state.invoicesUrl}>Bekijken/scannen</a></span></li>:null*/}
     <li>Orderbon:<span className="bill-detail-list-element">{this.state.invoicesUrl !='' ?<a target="_blank" href={this.state.invoicesUrl} >factuur</a>:'Geen Factuur'}</span></li>
     
                        </ul>
                    </Col>
                    <Col sm={5} className="bill-detail-list">
                    <span className="input-full-width input-status">
                        <label>Status</label><br/>
                        <select name="orderCountry" value={this.state.status} disabled={this.state.status == 'Afgehandeld' ?true:false} onChange={(event)=> this.changeStatus(event.target.value)}>
                            <option value="selected" disabled>Selecteer een status</option>
                            <option value="In afwachting">In afwachting</option>
                            <option value="In behandeling">In behandeling</option>
                            <option value="Afgehandeld">Afgehandeld</option>
                            <option value="Geannuleerd">Geannuleerd</option>
                        </select>
                    </span>
                    </Col>
                    <Col sm={8}></Col>
                <Col sm={11} className="bills-component">

<table className="bills-table"> 
  <tr>
    <th>Omschrijving</th>
    <th>Producenten</th>
    <th>Aantal</th>
    <th>Totaal</th>
  </tr>
  {this.state.items.map((item)=>(
    <tr className="items-table">
      <td><b>{item.name}</b><br/></td>
      <td>{this.state.producenten.filter((producent)=> producent.itemId == item.id).map((producent)=>( producent.shopName))}</td>
      <td>{item.amount}</td>
      <td>€ {(item.price * item.amount).toFixed(2)}</td>
  </tr>
  ))}

    
                   
 </table>
 </Col>

 <Col sm={11} className="bills-component">

<table className="bills-table"> 
  <tr>
    <th>Operking</th>
    
  </tr>
  
    <tr className="items-table">
      <td>{this.props.billData.billData.desc !=undefined ? this.props.billData.billData.desc:'Geen opmerking'}</td>
      
  </tr>


    
                   
 </table>
 </Col>

 
 <Col sm={12} className="black-space">

 </Col>
                </Row>
                
            
            
            </div>
        );
    }
};
export default OrderPostDetailComponent;
