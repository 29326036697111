import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { StaticRouter } from 'react-router';
import { set } from 'react-ga';
import { Satellite } from '@material-ui/icons';
import toast, { Toaster } from 'react-hot-toast';


class TasteIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            products:[],
            categories:[],
            selectedProduct:[],
            isProductSelected:false,
            clientUUID:'-ohlnfwebk',
            city:'',
            shops:[],
            products:[],
            selectedProducts:[],
            total:0,
            totalShow:'',
            paymentMethod:'',
            searchItem:'',
            creditCardset:false
        };
    }

    getProducts(){
    
        let ref = Firebase.database().ref('/cities/-onefoefw/shops/');
        ref.once('value' , snapshot => {
            this.setState({shops:[]})
           
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();
            console.log("resources:"+state);
            let shops = {id: state.id, companyName: state.companyName, open:false};
 
             this.setState(prevState => ({
                        shops: [...prevState.shops, shops],
                  
                    })) 

                    let products = Firebase.database().ref('/cities/-onefoefw/shops/'+ state.id+'/products/');
                    products.once('value' , snapshot => {
                            
                            snapshot.forEach((childSnap) => {
                            let newProduct = childSnap.val();
           
                       
                                let newProductSet ={shopId: state.id, productId: newProduct.id,activeVariations:newProduct.activeVariations,name:newProduct.name,price:newProduct.price,status:newProduct.status,stock:newProduct.stock,variations:newProduct.variations};
                                console.log(newProduct.activeVariations)
                                 this.setState(prevState => ({
                                     products: [...prevState.products, newProductSet],
                                 }));
                            
                            
                        
                    })
                })

                 

            
                



               

                

        })
    });
    }





    componentDidMount() {
        let today = new Date();

        console.log((today.getMonth()+1))

      this.getProducts();
     


        
    }
    changeAccordion( id){


        for(let i = 0; i < this.state.shops.length; i++){
   
            if(id ==  this.state.shops[i].id){
          
                let shops = [...this.state.shops];
                let shop = {...this.state.shops[i]};
                if(shop.open === true){
                    shop.open =false
                } else if(shop.open === false){
                    shop.open =true
                }

       
                
                shops[i] = shop

                this.setState({shops})


                //this.faqQuestions = this.faqQuestions;

        
            }
        }
    }
  

    addToBill(product, variant){
        console.log(product)

        if(product.activeVariations){
            let newTotal = variant.price +this.state.total;
            let addProduct ={id: product.productId, variantId: variant.id, name: product.name, variantName: variant.name,price:0,amount:1,shopId:product.shopId}
            this.setState(prevState => ({
                selectedProducts: [...prevState.selectedProducts,addProduct],
                total: newTotal,
                totalShow:parseFloat(newTotal).toFixed(2)
            }))
            
        }else{

            let newTotal = product.price +this.state.total;
      
            let addProduct ={id: product.productId, variantId: 0, name: product.name, variantName: '-',price:0,amount:1,shopId:product.shopId}
            this.setState(prevState => ({
                selectedProducts: [...prevState.selectedProducts,addProduct],
                total: newTotal,
                totalShow:parseFloat(newTotal).toFixed(2)
            }))
        }

        this.setState({
            searchItem:''
        })


       
    }

    changeAmount(change,productId,variant,variantId,price){
        console.log('id is'+productId)
        let newPrice = 0;
        for(let i=0;i< this.state.selectedProducts.length;i++){
 
           if(this.state.selectedProducts[i].id == productId){
               if(variant !='-'){
                console.log(' in the if')
                if(this.state.selectedProducts[i].variantId == variantId){
                    if(this.state.selectedProducts[i].amount < change){
                        newPrice = (this.state.total -(price* this.state.selectedProducts[i].amount)) + (change*price)
                    }else{
                        newPrice = (this.state.total-(price* this.state.selectedProducts[i].amount)) + (change*price)
                    }
                    this.state.selectedProducts[i].amount = change;
                  
                }
               }else{
                if(this.state.selectedProducts[i].amount < change){
                    newPrice = (this.state.total-(price* this.state.selectedProducts[i].amount)) + (change*price)
                }else{
                    newPrice = (this.state.total-(price* this.state.selectedProducts[i].amount)) + (change*price)
                }
                this.state.selectedProducts[i].amount = change;
               }
 
               this.setState({
                selectedProducts:this.state.selectedProducts,
                total:newPrice,
                totalShow:parseFloat(newPrice).toFixed(2)
               })
           }
        }

    }

    sendTransaction(){
        
        let today = new Date();
        var key = Firebase.database().ref().child('/cities/-onefoefw/orders/').push().key;
        Firebase.database().ref('/cities/-onefoefw/orders-cashdesk/'+today.getDate()+'-'+(today.getMonth()+1)+'-'+today.getFullYear()+'/'+key).set({
            email: '-',
           id:key,
           invoiceUrl:'-',
           items:JSON.stringify(this.state.selectedProducts),
           name:'-',
           newOrder:false,
           orderID:'-',
           payed:'paid',
           phone:'-',
           platform:'proeven',
           price:0,
           shipment:'-',
           status:'done',
           date: today,
           paymentMethod:'nvt'


    
        }).then((data)=>{
          
       
            
            this.state.selectedProducts.map((product)=>{
                let ref = Firebase.database().ref('/cities/-onefoefw/shops/'+product.shopId+'/products/'+product.id);
                ref.once('value' , snapshot => {
        
                    let state = snapshot.val();
                  
                    Firebase.database().ref('/cities/-onefoefw/shops/'+product.shopId+'/products/'+product.id).update({
                        stock: (state.stock-Number(product.amount)),
                       
                
                    })
                })
          
               
            })

       
           
    
            toast.success('Afboeken gelukt!')
        }).then(()=>{
            this.setState({
                selectedProducts:[],
                total:0,
                totalShow:'',
                paymentMethod:'',
                searchItem:'',
                creditCardset:false
            })
        })
       

    }

    deleteTotal(product,productId,shopId){
        
        this.setState({
            selectedProducts: this.state.selectedProducts.filter((item)=> item.id !== productId && item.shopId !== shopId),
        })
    }

    changePaymentMethod(item){
        this.setState({paymentMethod: item})

        if(item == 'Creditcard'){
            if(this.state.total <50){
                let newPrice = this.state.total+1;
                this.setState({
                    creditCardset: true,
                    total: newPrice,
                    totalShow:parseFloat(newPrice).toFixed(2)
                   })
            }else if(this.state.total > 50 && this.state.creditCardset){
                let newPrice = this.state.total-1;
                this.setState({
                    creditCardset: true,
                    total: newPrice,
                    totalShow:parseFloat(newPrice).toFixed(2)
                   })
            }
        }else if(item != 'Creditcard' && this.state.creditCardset){
            let newPrice = this.state.total-1;
            this.setState({
                creditCardset: false,
                total: newPrice,
                totalShow:parseFloat(newPrice).toFixed(2)
               })
        }


    }

    

    render() {
        return (
            <div className="cashdesk">  
                <Toaster />
                          <Row className="faq-row">
                          <Col sm={12}>
           
           <input type="text" placeholder="Zoeken..." id="icon" className="search-bar" value={this.state.searchItem} onChange={(event)=>this.setState({searchItem:event.target.value})} />
           </Col>
           {this.state.searchItem ?
           <Col sm={7}>
               
           <Col sm={12} className="products-ul">
           <ul>
                                      {this.state.products.filter((product)=> this.state.searchItem ? product.name.toLowerCase().includes(this.state.searchItem.toLowerCase()):null).map((product)=>(
                                          product.activeVariations 
                                          ?
                                          product.variations.map((variant)=>(
                                            <li><span className="product-name">{product.name } {variant.name}</span> <span className="product-stock">{variant.stock}</span> <span className="product-price">€ {parseFloat(variant.price).toFixed(2)}</span><span className="product-action"><Button onClick={()=>this.addToBill(product,variant,1)}>Toevoegen</Button></span></li>
                                          ))
                                          :
                                          <li><span className="product-name">{product.name}</span> <span className="product-stock ">{product.stock}</span> <span className="product-price">€ {parseFloat(product.price).toFixed(2)}</span><span className="product-action"><Button onClick={()=>this.addToBill(product,[],1)}>Toevoegen</Button></span></li>
                                      ))
                                    }

                                  </ul>
                                  </Col>
                                </Col>:
            this.state.shops.sort(function(a, b){
                return a.companyName === b.companyName ? 0 : a.companyName < b.companyName ? -1 : 1;
            }).map((item)=>(
      

           
                           <Col sm={7} className="faq-col" onClick={()=>this.changeAccordion(item.id)}>
                           <h5>{item.companyName}</h5>{item.open ? <i class="fas fa-chevron-up"></i>: <i class="fas fa-chevron-down"></i>}
                          {item.open ? <Row className="faq-row-para">
                               <Col sm={12} className="products-ul">
                                  <ul>
                                      {this.state.products.filter((product)=> product.shopId == item.id).map((product)=>(
                                          product.activeVariations 
                                          ?
                                          product.variations.map((variant)=>(
                                            <li><span className="product-name">{product.name} {variant.name}</span>  <span className="product-stock">{variant.stock}</span> <span className="product-price">€ {parseFloat(variant.price).toFixed(2)}</span><span className="product-action"><Button onClick={()=>this.addToBill(product,variant,1)}>Toevoegen</Button></span></li>
                                          ))
                                          :
                                          <li><span className="product-name">{product.name}</span> <span className="product-stock">{product.stock}</span> <span className="product-price">€ {parseFloat(product.price).toFixed(2)}</span><span className="product-action"><Button onClick={()=>this.addToBill(product,[],1)}>Toevoegen</Button></span></li>
                                      ))}

                                  </ul>
                               </Col>
                           </Row>:null}
                       </Col>

                      

           
         
                
    

            ))}
            <Col sm={4} className="order-bill">
              
                <h2>Af te boeken</h2>
                <ul>
                {this.state.selectedProducts.length >0?
                this.state.selectedProducts.map((product)=>(
                    <li><span className="product-name-total">{product.name}</span> <span className="product-name-total amount"><input type="number" min={1} value={product.amount}  onChange={(event)=>this.changeAmount(event.target.value,product.id,product.variantName,product.variantId,product.price)}/></span><span className="product-name-total price">{parseFloat(product.price*product.amount).toFixed(2)}</span><span className="delete"> {/*<Button  onClick={()=>this.deleteTotal(product,product.id,product.shopId)}>v</Button>*/}</span></li>
        )):
                <li>Nog geen producten</li>
                }
            </ul>
                <hr/>
               
               
                


                {  this.state.selectedProducts.length > 0 ?<Button className="pay-button" onClick={()=>this.sendTransaction()}>Afboeken</Button>:null}
            </Col>
     

        </Row>

</div>
        );
    }
};

export default TasteIndex;