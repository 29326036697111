import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { StaticRouter } from 'react-router';
import { set } from 'react-ga';
import { Satellite } from '@material-ui/icons';
import toast, { Toaster } from 'react-hot-toast';
import exportFromJSON from 'export-from-json'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
class RapportIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dayTotal:0,
            ordersToday:[],
            ordersOnline:[],
            orders:[],
            data: [
               
              ],
              currentValue:0
            
        };
    }
    

   

    renderPaymentOnline(){
        let paymentArray = [];
        for(let i =0; i< this.state.orders.length; i++){
   
            console.log(this.state.orders[i].items.length)

            this.state.orders[i].items.map((item)=>{
                let newItem = {EnkelOfVerzamel: this.state.orders[i].items.length > 1 ? 'VF': 'EF' ,Dag: this.state.orders[i].date,OrderID: this.state.orders[i].id,NaamMedewerker: 'Robin',BTW: item.btw+'%',NaamBehandeling: item.name, TotaalInclBtw: parseFloat(item.price).toFixed(2).replace('.',','), BTWPerBehandeling: parseFloat((item.price/100)*item.btw).toFixed(2).replace('.',','), Betaalmethode:this.state.orders[i].method}
            
                paymentArray.push(newItem)
            })
            
        
           
           /* */

        }

        return paymentArray;
    }

    exportBookings(){

        const data = this.renderPaymentOnline();

    const fileName = 'download'
    const exportType = 'xls'

    console.log(data)
    console.log(JSON.stringify(data))

    exportFromJSON({ data: data , fileName: 'Omzet_export', exportType: exportFromJSON.types.xls })
    }
    

    

    





   
    componentDidMount(){
        this.getOnline();
        this.getOrders();
        setTimeout(() => {
            this.calcPerDay();
        },2000);
     
    }

    getOrders(){
        console.log('here orders')
        let today = new Date();


    
            let ref = Firebase.database().ref('/clients/-3evre723ohfew/orders-cashdesk/');
            ref.once('value' , snapshot => {
                this.setState({shops:[]})
               
                snapshot.forEach((childSnap) => {
                let state = childSnap.val();
                


    
                let newOrder = {name: this.state.number,date:state.date,id: state.id, payed:state.payed, method:state.paymentMethod, price: state.price,items: state.items,open:false}
                this.setState(prevState => ({
                    orders: [...prevState.orders, newOrder],
          
                      
                        })) 
                        console.log(today.getDate()+'-'+(today.getMonth()+1)+'-'+today.getFullYear())
                      console.log(newOrder)
                     

                      if(today.getDate()+'-'+(today.getMonth()+1)+'-'+today.getFullYear() == newOrder.date){
                          console.log('same date'+ newOrder.price)
                        this.calcDayTotal(newOrder.price)
                      }
   
          
                   
    
                    
    
            })
            
        });
       
        
    


        }


       
    
    
        getOnline(){
        
            let ref = Firebase.database().ref('/cities/-onefoefw/orders/');
            ref.on('value' , snapshot => {
                this.setState({ordersOnline:[],ordersToday:[]})
               
                snapshot.forEach((childSnap) => {
                let state = childSnap.val();
               
                
    
                 this.setState(prevState => ({
                    ordersOnline: [...prevState.ordersOnline, state],
                      
                        })) 

                        this.calcDayTotal(state.price)

    
                        
                 
    
                     
    
                
                    
    
    
    
                   
    
                    
    
            })
        });
        }
    
        calcDayTotal(price){
            console.log('incoming price:' + price )
    
            let newPrice = price;

            let oldPrice = this.state.currentValue;
         
            let calcPrice = parseFloat(oldPrice) + parseFloat(newPrice);
        
            this.setState({
                currentValue: parseFloat(calcPrice).toFixed(2)
            })


    
        }

        calcPerDay(){

            const count = (arr, condition) => arr.filter(condition).length;
let today = new Date();
            let secondDecember = count(this.state.orders, (o) => o.date == '2-12-2021');
            let thirdDecember = count(this.state.orders, (o) => o.date == '3-12-2021');
for(let i = 1; i< (today.getDate()+1);i++){


            let countAmountOrders = count(this.state.orders, (o) => o.date == [i]+'-'+(today.getMonth()+1)+'-'+today.getFullYear());
            let countAmountOnlineOrders = count(this.state.ordersOnline, (o) => o.date == [i]+'-'+(today.getMonth()+1)+'-'+today.getFullYear());
            let newData = {name:[i]+'-'+(today.getMonth()+1)+'-'+today.getFullYear(),omzet:countAmountOrders,online:countAmountOnlineOrders}
            this.setState(prevState => ({
                data: [...prevState.data, newData],
      
                  
                    })) 
           
        }
           
        }

    render() {
        return (
            <div className="cashdesk">  
   
                          <Row >
                              <Col sm={12}>
                                  <Button onClick={()=>this.exportBookings()}>Exporteer totaal omzet</Button>
                                  
                              </Col>
                              <Col sm={4} className="counting-rapoort-field-4">

                                  <h3>Dag omzet vandaag:</h3>
                                  <h2>€ {this.state.currentValue}</h2>
                                
                            </Col>
                            <Col sm={8}>
                                </Col>

                              
       {/*} <Col sm={3} className='raportaget-tools'>
                                  <div className='rapportage-div'>
                                    <h2>Totaal fysieke bestellingen:</h2>
                                    <p>{this.state.orders.length}</p>
                                  </div>
                              </Col>
                              <Col sm={3} className='raportaget-tools'>
                              <div className='rapportage-div'>
                                  <h2>Totaal online bestellingen:</h2>
                                  <p>{this.state.ordersOnline.length}</p>
                                  </div>
                              </Col>
                              <Col sm={3} className='raportaget-tools'>
                              <div className='rapportage-div'>
                                  <h2>Huidige<br/> omzet:</h2>
                                  <p>€ {parseFloat(this.state.dayTotal).toFixed(2)}</p>
                                  </div>
        </Col>*/}

                   <Col sm={6} className='raportaget-tools'> 
                   <div  className='rapportage-div'>
        <LineChart
          width={500}
          height={300}
          data={this.state.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          {/*<Line type="monotone" dataKey="online" stroke="#8884d8" activeDot={{ r: 8 }} />*/}
          <Line type="monotone" dataKey="omzet" stroke="#82ca9d" />
        </LineChart>
        </div>
        </Col>          
    
  
           
       


   
   
            </Row>
</div>
        );
    }
};

export default RapportIndex;