import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button, number } from "shards-react";
import Firebase from 'firebase';

import toast, { Toaster } from 'react-hot-toast';
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Link } from 'react-router-dom';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
moment.locale('nl_nl');
const localizer = momentLocalizer(moment);

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));




class CalenderComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           eventDb: [
            {title: "boeking", id: 1,resourceId: 1,start: new Date(2021,1,4,8,0,0),end: new Date(2021,1,5,13,0,0)},
            {title: "boeking", id: 1,resourceId: 1,start: new Date(2021,1,4,8,0,0),end: new Date(2021,1,5,13,0,0)}
           ],
           resourceMap:[],
           planAppointment: false,
           changeAppointment: false,
           eventChange: [],
           selectedDate:null,
           ViewDate:new Date(),
           treatment:'',
           treatments:[],
           employee:'',
           timeSpend:0,
           startDateAppointment:new Date(),
           endDateAppointment:new Date(),
           addAppointmentOverlay:false,
           nameClient:'',
           changeAppointmentOverlay:false,
           changeId:''
   
        };

        this.handlePlanAppointment = this.handlePlanAppointment.bind(this);
        this.changePlanAppointment = this.handleChangeAppointment.bind(this);
        this.openChange = this.openChange.bind(this);
    }

    handlePlanAppointment(){
        console.log('lukt');
        this.setState({
            planAppointment: false
        });
    }

    openChange(event){
        console.log(event)
        this.setState({
            changeAppointmentOverlay:true,
            treatment:event.treatment,
            nameClient: event.title,
            startDateAppointment:new Date(event.startDateAppointment),
            endDateAppointment:new Date(event.endDateAppointment),
            changeId:event.id
        })  
    }

    deleteAppointment(){
        Firebase.database().ref('/clients/-3evre723ohfew/events/'+this.state.changeId).remove().then(()=>{
            toast.success('Afspraak verwijderd!')
            this.setState({
                treatment:'',
                nameClient:'',
           employee:'',
           timeSpend:0,
           changeId:'',
           startDateAppointment:new Date(),
           endDateAppointment:new Date(),
           addAppointmentOverlay:false,
           changeAppointmentOverlay: false,
            })
        })
    }

    changeAppointment(){
        console.log(this.state.changeId)

                Firebase.database().ref('/clients/-3evre723ohfew/events/'+this.state.changeId).update({
           
                   
                    treatment:this.state.treatment,
                    start:String(this.state.startDateAppointment),
                    end:String(this.state.endDateAppointment),
                    nameClient:this.state.nameClient,
                    resourceId:1,            
            
                }).then(()=>{
                    toast.success('Afspraak aangepast!')
                    this.setState({
                        treatment:'',
                        nameClient:'',
                   employee:'',
                   timeSpend:0,
                   changeId:'',
                   startDateAppointment:new Date(),
                   endDateAppointment:new Date(),
                   addAppointmentOverlay:false,
                   changeAppointmentOverlay: false,
                    })
                })
            }

    handleChangeAppointment(){

        this.setState({
            changeAppointment: false,
        });

    
    }

    CustomEvent = ({event}) =>{
        /* var backgroundColor = '';
         var icon = '';
     
         console.log('event is:'+event.resourceId);
         if(event.place === 'biejanssen.nl'){
             if(event.ispayed){icon = 'payed';}else{icon = 'warning-icon';}
             backgroundColor = '#27718F';
         }else if(event.place === 'booking.com'){
             if(event.ispayed){icon = 'payed';}else{icon = 'warning-icon';}
             backgroundColor = '#A560E0';
         }else if(event.place === 'airbnb.com'){
             if(event.ispayed){icon = 'payed';}else{icon = 'warning-icon';}
             backgroundColor = '#7AA898';
         }else if(event.place === 'bedandbreakfast.nl'){
             if(event.ispayed){icon = 'payed';}else{icon = 'warning-icon';}
             backgroundColor = '#E06060';
         }
        */
         return(
     
             <span className="custom-event" onClick={()=>this.openChange(event)}>
           
              <strong>{event.title}</strong><br/>
              <p>{event.treatment}</p>
     
            
     
         
     
                     
        
              
                 
     
             </span>
         )
     }

    getEvents(){
        let ref = Firebase.database().ref('/clients/-3evre723ohfew/events');
        
        ref.on('value' , snapshot => {
            this.setState({
                eventDb:[]
            })
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();
    
            
  
                let newEvents = {title: state.nameClient,treatment: state.treatment,start:new Date(state.start),end:new Date(state.end),id:state.key};
        
                this.setState(prevState => ({
                   eventDb: [...prevState.eventDb, newEvents]
                }))  

             
        })});

    }

    getTreatments(){
        let ref = Firebase.database().ref('/clients/-3evre723ohfew/treatments');
        
        ref.on('value' , snapshot => {
            this.setState({
                treatments:[]
            })
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();

            
  
               
        
                this.setState(prevState => ({
                    treatments: [...prevState.treatments, state]
                }))  

             
        })});
    }



    onEventResize = (data) => {
        const { start, end } = data;
    
        this.setState((state) => {
          state.events[0].start = start;
          state.events[0].end = end;
          return { events: state.events };
        });
      };
    
      onEventDrop = (data) => {
        console.log(data);
      };

    componentWillMount() {
      
        this.getEvents();
        this.getTreatments();

    }

    deletedEvent(pEvent){
        const r = window.confirm("Weet je zeker dat je de afspraak wilt verwijderen?");
        if(r === true){
            var ref = Firebase.database().ref('/clients/-3evre723ohfew/events');
            ref.orderByChild('id').equalTo(pEvent.id).on('child_added', (snapshot) => {
                snapshot.ref.remove();
            });

            this.setState({
                eventDb: this.state.eventDb.filter(item => item.id !== pEvent.id)
            })
        }
    }

    changeEvent(event){
this.setState({
    eventChange: event,
    changeAppointment: true
})
    }




    saveInfo() {
        var nd = new Date();
        var d = nd.getDate();
        var m = nd.getMonth() + 1;
        var y = nd.getFullYear();

        var dateStamp = d + '-' + m + '-' + y;

        var newPostKey = Firebase.database().ref().child('/q-sort').push().key;
        Firebase.database().ref('/' + dateStamp + '/' + newPostKey).set({
            companyName: this.state.companyName,
            participants: this.state.participants,
            questions: this.state.questions,
            stelling: this.state.stelling,
            key: newPostKey,
            user: 1,
        })

        this.setState({
            key: newPostKey,
        })
    }

    changeCalendarDate(date){
        console.log(new Date(date))
        this.setState({
            ViewDate: date
        })

    }

    changeDateAppointment(date){
        console.log(new Date(date).getHours())
        console.log(new Date(date).getMinutes())

        let selectedDate = new Date(date);
        let newDate = new Date();

       let endDate = moment(selectedDate).add(this.state.timeSpend, 'm').toDate();
console.log(endDate)
        this.setState({
            startDateAppointment: selectedDate,
            endDateAppointment:  endDate
        })

       
    }

    setTreatment(output){
        console.log(output.title)
        this.setState({
            treatment:output,
        })

        this.state.treatments.map((item)=>{
            if(output==item.gender + ' - '+item.title){
                this.setState({
                    timeSpend:Number(item.time)
                })
            }
        })
    }

    planAppointment(){
console.log(this.state.endDateAppointment)
        var key = Firebase.database().ref().child('/clients/-3evre723ohfew/events').push().key;
        Firebase.database().ref('/clients/-3evre723ohfew/events/'+key).set({
            key: key,
           
            treatment:this.state.treatment,
            start:String(this.state.startDateAppointment),
            end:String(this.state.endDateAppointment),
            nameClient:this.state.nameClient,
            resourceId:1,            
    
        }).then(()=>{
            toast.success('Afspraak gemaakt!')
            this.setState({
                treatment:'',
                nameClient:'',
           employee:'',
           timeSpend:0,
           startDateAppointment:new Date(),
           endDateAppointment:new Date(),
           addAppointmentOverlay:false
            })
        })
    }


    render() {
   
        let today = new Date;
        let localizer = momentLocalizer(moment);
    
        return (
            <div>
                {this.state.addAppointmentOverlay?
                <Row className={'overlay-row'}>

                    <Col sm={12}  className={'overlay-col'}>
                        <h2>Afspraak plannen</h2>
                        <TextField id="standard-basic" label="Naam klant" value={this.state.nameClient} onChange={(e)=>this.setState({nameClient:e.target.value})}/>
                        <FormControl >
        <InputLabel id="demo-simple-select-label">Behandeling</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={this.state.treatment}
          onChange={(e)=> this.setTreatment(e.target.value)}
        >
            {this.state.treatments.map((item)=>(
                  <MenuItem value={item.gender +' - '+item.title}>{item.gender} - {item.title}</MenuItem>
            ))}
        
        </Select>
      </FormControl>

      <TextField id="standard-basic" label="Tijd in minuten" value={this.state.timeSpend} onChange={(e)=>this.setState({timeSpend:e.target.value})}/>
      <br/><br/>
      <div className='space-in-form'></div>
      <TextField
        id="datetime-local"
        label="Afspraak"
        type="datetime-local"
        defaultValue={new Date()}
        className={''}
        onChange={(e)=>this.changeDateAppointment(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
      />

<FormControl >
        <InputLabel id="demo-simple-select-label">Kapper</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={this.state.employee}
          onChange={(e)=>this.setState({employee:e.target.value})}
        >
          <MenuItem value={'Robin'}>Robin</MenuItem>
  
        </Select>
      </FormControl>

      <Button variant="contained" color="primary" className='add-button' onClick={()=>this.planAppointment()}>
        Plannen
      </Button>
      <br/>
      <Button variant="contained" color="primary" className='add-button close-appointment' onClick={()=>this.setState({addAppointmentOverlay:false,changeAppointmentOverlay:false,treatment:'',
                        nameClient:'',
                   employee:'',
                   timeSpend:0,
                   changeId:'',
                   startDateAppointment:new Date(),
                   endDateAppointment:new Date()})}>
        Sluiten
      </Button>
                    </Col>
                </Row>:null}
                {this.state.changeAppointmentOverlay?
                <Row className={'overlay-row'}>

                    <Col sm={12}  className={'overlay-col'}>
                        <h2>Afspraak wijzigen</h2>
                        <TextField id="standard-basic" label="Naam klant" value={this.state.nameClient} onChange={(e)=>this.setState({nameClient:e.target.value})}/>
                        <FormControl >
        <InputLabel id="demo-simple-select-label">Behandeling</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={this.state.treatment}
          onChange={(e)=> this.setTreatment(e.target.value)}
        >
            {this.state.treatments.map((item)=>(
                  <MenuItem value={item.gender +' - '+item.title}>{item.gender} - {item.title}</MenuItem>
            ))}
        
        </Select>
      </FormControl>

      <TextField id="standard-basic" label="Tijd in minuten" value={this.state.timeSpend} onChange={(e)=>this.setState({timeSpend:e.target.value})}/>
      <br/><br/>
      <div className='space-in-form'></div>
      <TextField
        id="datetime-local"
        label="Afspraak"
        type="datetime-local"
        defaultValue={new Date()}
        className={''}
        onChange={(e)=>this.changeDateAppointment(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
      />

<FormControl >
        <InputLabel id="demo-simple-select-label">Kapper</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={this.state.employee}
          onChange={(e)=>this.setState({employee:e.target.value})}
        >
          <MenuItem value={'Robin'}>Robin</MenuItem>
  
        </Select>
      </FormControl>
      <Button variant="contained" color="warning" className='add-button delete' onClick={()=>this.deleteAppointment()}>
        Verwijder
      </Button>

      <Button variant="contained" color="primary" className='add-button' onClick={()=>this.changeAppointment()}>
        Plannen
      </Button>
      <br/>
      <Button variant="contained" color="primary" className='add-button close-appointment' onClick={()=>this.setState({addAppointmentOverlay:false,changeAppointmentOverlay:false,treatment:'',
                        nameClient:'',
                   employee:'',
                   timeSpend:0,
                   changeId:'',
                   startDateAppointment:new Date(),
                   endDateAppointment:new Date()})}>
        Sluiten
      </Button>
                    </Col>
                </Row>:null}

            <Row>
               {/*} <Col lg="9" >

                  <div className="legenda" style={{backgroundColor:'#E06060'}}>Bedandbreakfast.nl</div>
                  <div className="legenda" style={{backgroundColor:'#A560E0'}}>Booking.com</div>
                  
                  <div className="legenda" style={{backgroundColor:'#27718F'}}>Biejanssen.nl</div>
                  <div className="legenda-icon"><img src={require('../../assets/images/svg/warning-icon.svg')}/> Rekening open</div>
                  <div className="legenda-icon"><img src={require('../../assets/images/svg/payed.svg')}/> Betaald</div>
        </Col>*/}
                <Col xs="12" className={'add-colum'}>
                <Fab color='primary' aria-label='add' onClick={()=>this.setState({addAppointmentOverlay:true})}>
                   <AddIcon />
               </Fab>

        </Col>
                <Col lg="12" className="calendar-dashboard">
                    <input type="date" onChange={(event)=> this.changeCalendarDate(event.target.value)} />
                <Calendar
                showMultiDayTimes
                localizer={this.localizer}
                defaultDate={new Date()}
                date={this.state.ViewDate}
                defaultView="week"
                events={this.state.eventDb}
                localizer={localizer}
                formats = {{
                    timeGutterFormat: 'HH:mm',
                  }}
                onEventDrop={this.onEventDrop}
                onEventResize={this.onEventResize}
                resizable
                min={new Date(today.getFullYear(),today.getMonth(),today.getDate(), 9)}
                max={new Date(today.getFullYear(),today.getMonth(),today.getDate(), 23)}
             
                style={{ height: "100vh" }}
                views={['day','week']}
                timeslots={1}
                
                /*onSelectEvent={event => this.props.navigation.navi}*/
                step={15}
       
                culture={moment.locale('nl')}
                messages={{today:'Vandaag',previous:'<', next:'>',month:'Maand',week:'Week',day:'dag'}}
                components={{
                    event: this.CustomEvent
                  }}
        />
                  
                </Col>

                
            </Row>

            </div>

        );
    }
};

export default CalenderComponent;