import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';


class MobileNavigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clientUUID:'-ohlnfwebk',
            orders:[]
            
        };
    }





    componentDidMount() {

    }


    ToggleMenu(){
      document.getElementById('mobile-side-bar').style.transform = "translateX(0%)";
    }

    

    render() {
        return (
            <div>
         
            <Row className="col-12">

                <Col sm={8}>
                
            
                </Col>
                <Col sm={4} className="menu-button-mobile-backend">
                <Button onClick={()=>this.ToggleMenu()} ><i class="fas fa-bars"></i></Button>
            
                </Col>
   
            </Row>
            </div>



        );
    }
};

export default MobileNavigation;