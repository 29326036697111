import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../../components/common/PageTitle";

import OrdersDetailsComponent from "../../components/orders/orderDetails";
import MobileNavigation from "../../layouts/mobileNavigation";



const OrdersDetailView = (props) => (
  <Container fluid className="main-content-container px-4">
    <MobileNavigation />
    <Row noGutters className="page-header py-4">
      <PageTitle title="Bestelling - Afhalen" subtitle="Beter Bij Ons" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>

  <OrdersDetailsComponent billData={props.location.state}/>
    
  </Container>
);

export default OrdersDetailView;
