import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import OrdersIndex from "../../components/orders";
import OrdersDelivery from "../../components/orders/ordersDelivery";
import OrdersPost from "../../components/orders/ordersPost";
import MobileNavigation from "../../layouts/mobileNavigation";



const OrdersPostView = () => (
  <Container fluid className="main-content-container px-4">
    <MobileNavigation />
    <Row noGutters className="page-header py-4">
      <PageTitle title="Bestellingen - Post" subtitle="Beter bij ons" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>
    <OrdersPost />
    
  </Container>
);

export default OrdersPostView;
