import React, { Component,PureComponent } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


class ChartsIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dayTotal:0,
            ordersToday:[],
            ordersOnline:[],
            orders:[],
            data: [
               
              ]
        };
    }


    getOrders(){
        console.log('here orders')
        let today = new Date();
        for(let i = 2; i < (today.getDate()+1);i++){

    
            let ref = Firebase.database().ref('/cities/-onefoefw/orders-cashdesk/'+[i]+'-'+(today.getMonth()+1)+'-'+today.getFullYear()+'/');
            ref.once('value' , snapshot => {
                this.setState({shops:[]})
               
                snapshot.forEach((childSnap) => {
                let state = childSnap.val();
                
                let items = JSON.parse(state.items);

    
                let newOrder = {name: this.state.number,date:[i]+'-'+(today.getMonth()+1)+'-'+today.getFullYear(),id: state.id, payed:state.payed, method:state.paymentMethod, price: state.price,items: JSON.parse(state.items),open:false}
                this.setState(prevState => ({
                    orders: [...prevState.orders, newOrder],
          
                      
                        })) 
                      console.log(newOrder)
                      this.calcDayTotal(state.price)
    
                
    
    
                
    
                        
                        
                 
    
                     
    
                
                    
    
    
    
                   
    
                    
    
            })
            
        });
       
        
    }


        }


       
    
    
        getOnline(){
        
            let ref = Firebase.database().ref('/cities/-onefoefw/orders/');
            ref.on('value' , snapshot => {
                this.setState({ordersOnline:[],ordersToday:[]})
               
                snapshot.forEach((childSnap) => {
                let state = childSnap.val();
               
                
    
                 this.setState(prevState => ({
                    ordersOnline: [...prevState.ordersOnline, state],
                      
                        })) 

                        this.calcDayTotal(state.price)

    
                        
                 
    
                     
    
                
                    
    
    
    
                   
    
                    
    
            })
        });
        }
    
        calcDayTotal(price){
    
            let newPrice = (Number(price)/100);
            let oldPrice = this.state.dayTotal;
            let calcPrice = oldPrice +newPrice;
    
            this.setState({
                dayTotal: calcPrice
            })
    
        }

        calcPerDay(){

            const count = (arr, condition) => arr.filter(condition).length;
let today = new Date();
            let secondDecember = count(this.state.orders, (o) => o.date == '2-12-2021');
            let thirdDecember = count(this.state.orders, (o) => o.date == '3-12-2021');
for(let i = 2; i< (today.getDate()+1);i++){


            let countAmountOrders = count(this.state.orders, (o) => o.date == [i]+'-'+(today.getMonth()+1)+'-'+today.getFullYear());
            let countAmountOnlineOrders = count(this.state.ordersOnline, (o) => o.date == [i]+'-'+(today.getMonth()+1)+'-'+today.getFullYear());
            let newData = {name:[i]+'-'+(today.getMonth()+1)+'-'+today.getFullYear(),fysiek:countAmountOrders,online:countAmountOnlineOrders}
            this.setState(prevState => ({
                data: [...prevState.data, newData],
      
                  
                    })) 
           
        }
           
        }


        componentDidMount(){
            this.getOnline();
            this.getOrders();
            setTimeout(() => {
                this.calcPerDay();
            },2000);
         
        }

  
   
    

    render() {
        return (
            <div className="cashdesk">
            <Row >
                {/*<Col sm={12} className="products-actions">
                    <Link>Product bewerken</Link>
                    <Link>Meer acties</Link>
                    <Link className="align-button-right" to={{pathname: `/producten/toevoegen`}} >Product toevoegen</Link>
        </Col>*/}
        <Col sm={3} className='raportaget-tools'>
                                  <div className='rapportage-div'>
                                    <h2>Totaal fysieke bestellingen:</h2>
                                    <p>{this.state.orders.length}</p>
                                  </div>
                              </Col>
                              <Col sm={3} className='raportaget-tools'>
                              <div className='rapportage-div'>
                                  <h2>Totaal online bestellingen:</h2>
                                  <p>{this.state.ordersOnline.length}</p>
                                  </div>
                              </Col>
                              <Col sm={3} className='raportaget-tools'>
                              <div className='rapportage-div'>
                                  <h2>Huidige<br/> omzet:</h2>
                                  <p>€ {parseFloat(this.state.dayTotal).toFixed(2)}</p>
                                  </div>
                              </Col>

                   <Col sm={6} className='raportaget-tools'> 
                   <div  className='rapportage-div'>
        <LineChart
          width={500}
          height={300}
          data={this.state.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="online" stroke="#8884d8" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="fysiek" stroke="#82ca9d" />
        </LineChart>
        </div>
        </Col>          

  
           
       


   
   
            </Row>
            </div>



        );
    }
};
export default ChartsIndex;