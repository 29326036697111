

export default function() {
  return [
    /*  {
      title: "Bestellingen",
      to: "/bestellingen",
      htmlBefore: 'shopping-cart',
      htmlAfter: ""
    }, */
    {
      title: "Kassa",
      to: "/kassa",
      htmlBefore: 'https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/Vanity%2FKASSA.svg?alt=media&token=690b544f-126f-4a21-9762-bed027bcd4b6',
      htmlAfter: ""
    },
    {
      title: "Agenda",
      to: "/agenda",
      htmlBefore: 'https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/Vanity%2FAGENDA.svg?alt=media&token=bd412503-e5fe-4ecc-82e8-43148ca000d5',
      htmlAfter: ""
    }, 
   
  
    {
      title: "Kassa tellen",
      to: "/kassa-tellen",
      htmlBefore: 'https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/Vanity%2FKASTELLEN%202.svg?alt=media&token=a3db4430-8e29-4d19-927a-82e3cdbd225d',
      htmlAfter: ""
    }, 
    {
      title: "Rapportages",
      to: "/rapportage",
      htmlBefore: 'https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/Vanity%2FRAPPORTAGES.svg?alt=media&token=4807e3a4-1ed4-42dd-a902-06dbcab9de4b',
      htmlAfter: ""
    }, 
    {
      title: "Beheer",
      to: "/producten",
      htmlBefore: 'https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/Vanity%2FBEHEER.svg?alt=media&token=532c723e-2811-4899-8866-6b8fc6d87706',
      htmlAfter: ""
    }, 

   
 
  ];
}
