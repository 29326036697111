import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, ModalLayout } from "./layouts";
import SignOnCompleteView from "./views/Authentication/SignOnCompleteView";
import SignOnView from "./views/Authentication/SignOnView";
import CalendarView from "./views/Calender/CalenderView";
import ChartView from "./views/Charts/ChartView";
import Counting from "./views/Counting/CountingView";
import LoginScreen from "./views/LoginScreen";
import Message from "./views/Messages/MessageView";
import OrderDeliveryDetailView from "./views/Orders/OrderDeliveryDetailView";
import OrdersDetailView from "./views/Orders/OrderDetailView";
import OrderPostDetailView from "./views/Orders/OrderPostDetailView";
import OrdersDeliveryView from "./views/Orders/OrdersDeliveryView";
import OrdersPostView from "./views/Orders/OrdersPostView";
import Orders from "./views/Orders/OrdersView";
import AddProductsView from "./views/Products/AddProductsView";
import ChangeProductsView from "./views/Products/ChangeProductsView";
import DayPricesView from "./views/Products/DayPricesView";
import ProductsOverview from "./views/Products/ProductsOverview";
import Products from "./views/Products/ProductsView";
import TasteView from "./views/Products/TasteView";
import Profile from "./views/Profile/ProfileView";
import Rapport from "./views/Rapport/RapportView";


// Route Views

import SignInScreen from "./views/signInView";



export default [
  {
    path: "/",
    exact: false,
    layout: ModalLayout,
    component: () => <Redirect to="/producten" />
  },
  {
    path: "/berichten",
    exact: true,
    layout: DefaultLayout,
    component: Message
  },
  {
    path: "/kassa",
    exact: true,
    layout: DefaultLayout,
    component: Products
  },
  {
    path: "/producten",
    exact: true,
    layout: DefaultLayout,
    component: ProductsOverview
  },
  {
    path: "/producten/toevoegen",
    exact: true,
    layout: DefaultLayout,
    component: AddProductsView
  },
  {
    path: "/producten/aanpassen",
    exact: true,
    layout: DefaultLayout,
    component: ChangeProductsView
  },
  {
    path: "/profiel",
    exact: true,
    layout: DefaultLayout,
    component: Profile
  },
  {
    path: "/aanmelden",
    exact: true,
    layout: ModalLayout,
    component: SignOnView
  },
  
  {
    path: "/afhalen",
    exact: true,
    layout: DefaultLayout,
    component: Orders
  },
  {
    path: "/agenda",
    exact: true,
    layout: DefaultLayout,
    component: CalendarView
  },
  {
    path: "/afhalen/details",
    exact: true,
    layout: DefaultLayout,
    component: OrdersDetailView
  },

  {
    path: "/bezorgen",
    exact: true,
    layout: DefaultLayout,
    component: OrdersDeliveryView
  },
  {
    path: "/bezorgen/details",
    exact: true,
    layout: DefaultLayout,
    component: OrderDeliveryDetailView
  },
  {
    path: "/rapportage",
    exact: true,
    layout: DefaultLayout,
    component: Rapport
  },
  {
    path: "/kassa-tellen",
    exact: true,
    layout: DefaultLayout,
    component: Counting
  },
  {
    path: "/afboeken",
    exact: true,
    layout: DefaultLayout,
    component: TasteView
  },
  {
    path: "/data",
    exact: true,
    layout: DefaultLayout,
    component: ChartView
  },
  {
    path: "/post",
    exact: true,
    layout: DefaultLayout,
    component: OrdersPostView
  },
  {
    path: "/post/details",
    exact: true,
    layout: DefaultLayout,
    component: OrderPostDetailView
  },

  {
    path: "/bestellingen",
    exact: true,
    layout: DefaultLayout,
    component: Orders
  },
  {
    path: "/bestellingen/details",
    exact: true,
    layout: DefaultLayout,
    component: OrdersDetailView
  },
  


  {
    path: "/inloggen",
    layout: ModalLayout,
    exact: true,
    component: LoginScreen
  },
];
