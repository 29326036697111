import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button, number } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { StaticRouter } from 'react-router';
import { set } from 'react-ga';
import { Satellite } from '@material-ui/icons';
import toast, { Toaster } from 'react-hot-toast';


class ProductsIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            products:[],
            categories:[],
            selectedProduct:[],
            isProductSelected:false,
            clientUUID:'-ohlnfwebk',
            city:'',
            treatments:[],
            products:[],
            selectedProducts:[],
            total:0,
            totalShow:'',
            paymentMethod:'',
            searchItem:'',
            creditCardset:false,
            gettedAmount:0,
            change:0
        };
    }

    getProducts(){
    
        let ref = Firebase.database().ref('/clients/-3evre723ohfew/treatments/');
        ref.once('value' , snapshot => {
            this.setState({treatments:[]})
           
            snapshot.forEach((childSnap) => {
            let state = childSnap.val(); 
             this.setState(prevState => ({
                        treatments: [...prevState.treatments, state],
                  
                    })) 
                

        })
    });
    }





    componentDidMount() {
        let today = new Date();

        console.log((today.getMonth()+1))

      this.getProducts();
     


        
    }
    changeAccordion( id){


        for(let i = 0; i < this.state.shops.length; i++){
   
            if(id ==  this.state.shops[i].id){
          
                let shops = [...this.state.shops];
                let shop = {...this.state.shops[i]};
                if(shop.open === true){
                    shop.open =false
                } else if(shop.open === false){
                    shop.open =true
                }

       
                
                shops[i] = shop

                this.setState({shops})


                //this.faqQuestions = this.faqQuestions;

        
            }
        }
    }
  

    addToBill(product, variant){
        console.log(product)

        if(product.activeVariations){
            let newTotal = variant.price +this.state.total;
            let addProduct ={id: product.id, name: product.title, price:variant.price,amount:1}
            this.setState(prevState => ({
                selectedProducts: [...prevState.selectedProducts,addProduct],
                total: newTotal,
                totalShow:parseFloat(newTotal).toFixed(2)
            }))
            
        }else{

            let newTotal = product.price +this.state.total;
      
            let addProduct ={id: product.id, name: product.title, price:product.price,amount:1,btw: product.btw}
            this.setState(prevState => ({
                selectedProducts: [...prevState.selectedProducts,addProduct],
                total: newTotal,
                totalShow:parseFloat(newTotal).toFixed(2)
            }))
        }

        this.setState({
            searchItem:''
        })


       
    }

    changeAmount(change,productId,variant,variantId,price){
        console.log('id is'+productId)
        let newPrice = 0;
        for(let i=0;i< this.state.selectedProducts.length;i++){
 
           if(this.state.selectedProducts[i].id == productId){

                if(this.state.selectedProducts[i].amount < change){
                    newPrice = (this.state.total-(price* this.state.selectedProducts[i].amount)) + (change*price)
                }else{
                    newPrice = (this.state.total-(price* this.state.selectedProducts[i].amount)) + (change*price)
                }
                this.state.selectedProducts[i].amount = change;
               }
 
               this.setState({
                selectedProducts:this.state.selectedProducts,
                total:newPrice,
                totalShow:parseFloat(newPrice).toFixed(2)
               })
           
        }

    }

    sendTransaction(){
        
        let today = new Date();
        var key = Firebase.database().ref().child('/clients/-3evre723ohfew/orders/').push().key;
        Firebase.database().ref('/clients/-3evre723ohfew/orders-cashdesk/'+key).set({
            email: '-',
           id:key,
           invoiceUrl:'-',
           items:this.state.selectedProducts,
           name:'-',
           newOrder:false,
           orderID:'-',
           payed:'paid',
           phone:'-',
           platform:'fysiek',
           price:this.state.total,
           shipment:'-',
           status:'done',
           date: today,
           paymentMethod:this.state.paymentMethod,
          date:today.getDate()+'-'+(today.getMonth()+1)+'-'+today.getFullYear()

    
        }).then(()=>{
            toast.success('Betaling gelukt!')
            this.setState({
                selectedProducts:[],
                total:0,
                totalShow:'',
                paymentMethod:'',
                searchItem:'',
                creditCardset:false
            })
        })
       

    }

    deleteTotal(product,productId,shopId){
        
        this.setState({
            selectedProducts: this.state.selectedProducts.filter((item)=> item.id !== productId && item.shopId !== shopId),
        })
    }

    changePaymentMethod(item){
        this.setState({paymentMethod: item})

        if(item == 'Creditcard'){
            if(this.state.total <50){
                let newPrice = this.state.total+1;
                this.setState({
                    creditCardset: true,
                    total: newPrice,
                    totalShow:parseFloat(newPrice).toFixed(2)
                   })
            }else if(this.state.total > 50 && this.state.creditCardset){
                let newPrice = this.state.total-1;
                this.setState({
                    creditCardset: true,
                    total: newPrice,
                    totalShow:parseFloat(newPrice).toFixed(2)
                   })
            }
        }else if(item != 'Creditcard' && this.state.creditCardset){
            let newPrice = this.state.total-1;
            this.setState({
                creditCardset: false,
                total: newPrice,
                totalShow:parseFloat(newPrice).toFixed(2)
               })
        }


    }

    

    render() {
        return (
            <div className="cashdesk">  
                <Toaster />
                          <Row className="faq-row">
                          <Col sm={12} className='search-row'>
           
           <input type="text" placeholder="Zoeken..." id="icon" className="search-bar" value={this.state.searchItem} onChange={(event)=>this.setState({searchItem:event.target.value})} />
           </Col>
           {this.state.searchItem ?
           <Col sm={7}>
               
           <Col sm={12} className="products-ul">
           <ul>
                                      {this.state.treatments.filter((product)=> this.state.searchItem ? product.title.toLowerCase().includes(this.state.searchItem.toLowerCase()):null).map((item)=>(
                                            <li><span className="product-name">{item.gender} - {item.title}</span>  <span className="product-price">€ {parseFloat(item.price).toFixed(2)}</span><span className="product-action"><Button onClick={()=>this.addToBill(item,[],1)}>Toevoegen</Button></span></li>
                                      ))
                                    }

                                  </ul>
                                  </Col>
                                </Col>:
            this.state.treatments.sort(function(a, b){
                return a.gender === b.gender ? 0 : a.gender < b.gender ? -1 : 1;
            }).map((item)=>(
      

           
                           

                               <Col sm={7} className="products-ul">
                                  <ul>

                                          <li><span className="product-name">{item.gender} - {item.title}</span>  <span className="product-price">€ {parseFloat(item.price).toFixed(2)}</span><span className="product-action"><Button onClick={()=>this.addToBill(item,[],1)}>Toevoegen</Button></span></li>
                                   

                                  </ul>
                     
                     
                       </Col>

                      

           
         
                
    

            ))}
            <Col sm={4} className="order-bill">
              
                <h2>Totaal</h2>
                <ul>
                {this.state.selectedProducts.length >0?
                this.state.selectedProducts.map((product)=>(
                    <li><span className="product-name-total">{product.name}</span> <span className="product-name-total amount"><input type="number" min={1} value={product.amount}  onChange={(event)=>this.changeAmount(event.target.value,product.id,product.variantName,product.variantId,product.price)}/></span><span className="product-name-total price">{parseFloat(product.price*product.amount).toFixed(2)}</span><span className="delete"> {/*<Button  onClick={()=>this.deleteTotal(product,product.id,product.shopId)}>v</Button>*/}</span></li>
        )):
                <li>Nog geen producten</li>
                }
            </ul>
                <hr/>
                <span className="total-text">Betaalmethoden:</span>
                <span className="payment-method">
                <select value={this.state.paymentMethod} onChange={(event)=> this.changePaymentMethod(event.target.value)}>
                    <option >Selecteer optie</option>
                    <option value="Pin">Pin</option>
                    <option value="Contant">Contant</option>
                     <option value="Creditcard">Creditcard</option>
                </select>
                </span>
               
                
                <span className="total-text">Totaal:</span>
                <span className="total-price">{this.state.totalShow =='' ? '-': '€ '+this.state.totalShow}</span>
                {  this.state.selectedProducts.length > 0 && this.state.paymentMethod !='' ?<Button className="pay-button" onClick={()=>this.sendTransaction()}>Betalen</Button>:null}
            </Col>

            {this.state.paymentMethod == 'Contant'?   <Col sm={4 }  className="math-help">
            <h2>Rekenhulp</h2>
           <span className="total-text">Ontvangen bedrag:</span>
               <span className="total-text"><input type="text" value={this.state.gettedAmount} onChange={(e)=>this.setState({gettedAmount:e.target.value.replace(',','.    m start')})}/></span>
                           <span className="total-text">Terug te betalen:</span>
                           <span className="total-text">{this.state.gettedAmount == 0 ? '-':(parseFloat(this.state.gettedAmount)-this.state.totalShow).toFixed(2)}</span>
               
            </Col>:null}
     

        </Row>

</div>
        );
    }
};

export default ProductsIndex;