import React from "react";
import { Nav } from "shards-react";

import SidebarNavItem from "./SidebarNavItem";
import SidebarNavLogout from "./SidebarNavLogout";
import { Store } from "../../../flux";
import Firebase from 'firebase';
class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      navItems: Store.getSidebarItems()
    };

    this.onChange = this.onChange.bind(this);
  }

  handleSignout(){

    Firebase.auth().signOut();
  
  
  
      }

  componentWillMount() {
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      ...this.state,
      navItems: Store.getSidebarItems()
    });
  }


  render() {
    const { navItems: items } = this.state;
    return (
      <div className="nav-wrapper">
        <Nav className="nav--no-borders flex-column nav-ffort">
          {items.map((item, idx) => (
            <SidebarNavItem key={idx} item={item} />
       
          ))}
          <span className="version-control">versie 3.3.1</span>
<div className="logout-button"><a onClick={() => this.handleSignout()}><i class="material-icons">exit_to_app</i> Uitloggen</a></div>
        </Nav>
      </div>
    )
  }
}

export default SidebarNavItems;
