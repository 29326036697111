import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import OrderDeliveryDetailComponent from "../../components/orders/orderDeliveryDetails";

import OrdersDetailsComponent from "../../components/orders/orderDetails";
import MobileNavigation from "../../layouts/mobileNavigation";



const OrderDeliveryDetailView = (props) => (
  <Container fluid className="main-content-container px-4">
    <MobileNavigation />
    <Row noGutters className="page-header py-4">
      <PageTitle title="Bestelling - Bezorgen" subtitle="Beter Bij Ons" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>

  <OrderDeliveryDetailComponent billData={props.location.state}/>
    
  </Container>
);

export default OrderDeliveryDetailView;
