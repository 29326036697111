import React from "react";
import { Container, Row, Col } from "shards-react";
import ChartsIndex from "../../components/charts/chartsComponent";

import PageTitle from "../../components/common/PageTitle";
import OrdersIndex from "../../components/orders";
import MobileNavigation from "../../layouts/mobileNavigation";



const ChartView = () => (
  <Container className="main-content-container px-4">
      <ChartsIndex />
  </Container>
);

export default ChartView;
