import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import ProductsIndex from "../../components/products";
import TasteIndex from "../../components/products/tasteProduct";
import MobileNavigation from "../../layouts/mobileNavigation";



const TasteView = () => (
  <Container fluid className="main-content-container px-4">
    <MobileNavigation />
    <Row noGutters className="page-header py-4">
      <PageTitle title="Afboeken" subtitle="Beter bij ons" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>
    <TasteIndex />
    
  </Container>
);

export default TasteView;
