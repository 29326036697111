import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, number } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { StaticRouter } from 'react-router';
import { set } from 'react-ga';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import toast, { Toaster } from 'react-hot-toast';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

class CountingComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hunderd:0,
            vifty:0,
            twenty:0,
            five:0,
            ten:0,
            two:0,
            one:0,
            viftyCent:0,
            twentyCent:0,
            tenCent:0,
            fiveCent:0,
            countings:[]
        };
    }

    saveToday(){
        let today = new Date();
        var key = Firebase.database().ref().child('/clients/-3evre723ohfew/cashdesk-counts/').push().key;
        Firebase.database().ref('/clients/-3evre723ohfew/cashdesk-counts/'+key).set({

           id:key,
          
          date:today.getDate()+'-'+(today.getMonth()+1)+'-'+today.getFullYear() +' / ' + today.getHours()+':'+today.getMinutes(),
          hunderd:this.state.hunderd,
          vifty:this.state.vifty,
          twenty:this.state.twenty,
          five:this.state.five,
          ten:this.state.ten,
          two:this.state.two,
          one:this.state.one,
          viftyCent:this.state.viftyCent,
          twentyCent:this.state.twentyCent,
          tenCent:this.state.tenCent,
          fiveCent:this.state.fiveCent,
          total:parseFloat((100*Number(this.state.hunderd)) +(50*Number(this.state.vifty)) +(20*Number(this.state.twenty))+(10*Number(this.state.ten))+(5*Number(this.state.five))+(2*Number(this.state.two))+(1*Number(this.state.one)) +(0.5*Number(this.state.viftyCent))+(0.2*Number(this.state.twentyCent))+(0.05*Number(this.state.fiveCent)) ).toFixed(2)

    
        }).then(()=>{
            toast.success('Telling gelukt!')
            this.setState({
                hunderd:0,
                vifty:0,
                twenty:0,
                five:0,
                ten:0,
                two:0,
                one:0,
                viftyCent:0,
                twentyCent:0,
                tenCent:0,
                fiveCent:0,
           
            })
        })
    }

    getCountings(){
    
        let ref = Firebase.database().ref('/clients/-3evre723ohfew/cashdesk-counts');
        ref.on('value' , snapshot => {
            this.setState({countings:[]})
           
            snapshot.forEach((childSnap) => {
            let state = childSnap.val(); 
             this.setState(prevState => ({
                countings: [...prevState.countings, state],
                  
                    })) 
                

        })
    });
    }



    
    createData(name, calories, fat, carbs, protein) {
        return { name, calories, fat, carbs, protein };
      }
   
      componentDidMount(){
          this.getCountings();
          let today = new Date();

        //  console.log(today.getDate()+'-'+(today.getMonth()+1)+'-'+today.getFullYear() +' / ' + today.getHours()+':'+today.getMinutes())
      }
    

    render() {

        return (
<Row>
<Toaster />
    <Col sm={1} xs={6}><TextField id="standard-basic" label="50 euro" value={this.state.vifty} onChange={(e)=>this.setState({vifty:e.target.value})}/></Col>
    <Col sm={1} xs={6}><TextField id="standard-basic" label="20 euro" value={this.state.twenty} onChange={(e)=>this.setState({twenty:e.target.value})}/></Col>
    <Col sm={1} xs={6}><TextField id="standard-basic" label="10 euro" value={this.state.ten} onChange={(e)=>this.setState({ten:e.target.value})}/></Col>
    <Col sm={1} xs={6}><TextField id="standard-basic" label="5 euro" value={this.state.five} onChange={(e)=>this.setState({five:e.target.value})}/></Col>
    <Col sm={1} xs={6}><TextField id="standard-basic" label="2 euro" value={this.state.two} onChange={(e)=>this.setState({two:e.target.value})}/></Col>
    <Col sm={1} xs={6}><TextField id="standard-basic" label="1 euro" value={this.state.one} onChange={(e)=>this.setState({one:e.target.value})}/></Col>
    <Col sm={1} xs={6}><TextField id="standard-basic" label="50 cent" value={this.state.viftyCent} onChange={(e)=>this.setState({viftyCent:e.target.value})}/></Col>
    <Col sm={1} xs={6}><TextField id="standard-basic" label="20 cent" value={this.state.twentyCent} onChange={(e)=>this.setState({twentyCent:e.target.value})}/></Col>
    <Col sm={1} xs={6}><TextField id="standard-basic" label="10 cent" value={this.state.tenCent} onChange={(e)=>this.setState({tenCent:e.target.value})}/></Col>
    <Col sm={1} xs={6}><TextField id="standard-basic" label="5 cent" value={this.state.fiveCent} onChange={(e)=>this.setState({fiveCent:e.target.value})}/></Col>
<Col sm={1} xs={12} className='save-counting'>
    <Button variant="contained" color="primary" onClick={()=>this.saveToday()}>
        Opslaan
    </Button>
</Col>
<Col sm={4} xs={12} className='total-count'>
    <h2>Totaal: € {parseFloat((100*Number(this.state.hunderd)) +(50*Number(this.state.vifty)) +(20*Number(this.state.twenty))+(10*Number(this.state.ten))+(5*Number(this.state.five))+(2*Number(this.state.two))+(1*Number(this.state.one)) +(0.5*Number(this.state.viftyCent))+(0.2*Number(this.state.twentyCent))+(0.05*Number(this.state.fiveCent)) ).toFixed(2)}</h2>
</Col>


<Col sm={12} className='history-countings'>

    <h2>Historie</h2>
<TableContainer component={Paper}>
      <Table className={'table'} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Datum</TableCell>
            <TableCell align="right">50 euro</TableCell>
            <TableCell align="right">20 euro</TableCell>
            <TableCell align="right">10 euro</TableCell>
            <TableCell align="right">5 euro</TableCell>
            <TableCell align="right">2 euro</TableCell>
            <TableCell align="right">1 euro</TableCell>
            <TableCell align="right">50 cent</TableCell>
            <TableCell align="right">20 cent</TableCell>
            <TableCell align="right">10 cent</TableCell>
            <TableCell align="right">5 cent</TableCell>
            <TableCell align="right">Totaal</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {this.state.countings.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.date}
              </TableCell>
              <TableCell align="right">{row.vifty}</TableCell>
              <TableCell align="right">{row.twenty}</TableCell>
              <TableCell align="right">{row.ten}</TableCell>
              <TableCell align="right">{row.five}</TableCell>
              <TableCell align="right">{row.two}</TableCell>
              <TableCell align="right">{row.one}</TableCell>
              <TableCell align="right">{row.viftyCent}</TableCell>
              <TableCell align="right">{row.twentyCent}</TableCell>
              <TableCell align="right">{row.tenCent}</TableCell>
              <TableCell align="right">{row.fiveCent}</TableCell>
              <TableCell align="right">€ {row.total}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
</Col>
</Row>
        );
    }
};

export default CountingComponent;