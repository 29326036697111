import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';


class OrdersIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clientUUID:'-ohlnfwebk',
            orders:[],
            searchItem:''
            
        };
    }

    getOrders(){
       
    
        let ref = Firebase.database().ref('/cities/-onefoefw/orders');
        ref.on('value' , snapshot => {
            this.setState({
                orders:[]
            })
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();
 

       
                this.setState(prevState => ({
                    orders: [...prevState.orders, state]
                }))  
            })

        });

    }



    componentDidMount() {
      
        this.setState({
            clientUUID: Firebase.auth().currentUser.uid
        })
        this.getOrders();
    }

    

    render() {
        return (
            <div>
            <Row className="col-sm-12">
                {/*<Col sm={12} className="products-actions">
                    <Link>Product bewerken</Link>
                    <Link>Meer acties</Link>
                    <Link className="align-button-right" to={{pathname: `/producten/toevoegen`}} >Product toevoegen</Link>
        </Col>*/}
        <Col sm={12}>
           
        <input type="text" placeholder="Zoeken..." id="icon" className="search-bar" value={this.state.searchItem} onChange={(event)=>this.setState({searchItem:event.target.value})} />
        </Col>
        {this.state.searchItem ?
        <Col sm={12} className="products-table">
                <h3>Zoek resultaten</h3>
            <table>
                <tr>
                    <th>Order nr</th>
                    <th>Klant naam</th>
                    <th>Status</th>
                    <th>Betaald</th>
                    <th>Actie</th>
                </tr>

                {this.state.orders.filter((item) => this.state.searchItem ? item.orderID.toLowerCase().includes(this.state.searchItem.toLowerCase()) || item.name.toLowerCase().includes(this.state.searchItem.toLowerCase()):item.name == item.name).map((order)=>(
                    <tr >
                        <td>{order.orderID}</td>
                        <td>{order.name}</td>
                        <td>{order.status === 'in progress' ?<i class="fas fa-ellipsis-h"></i>:null } {order.status === 'cancelled' ?<i class="fas fa-times"></i>:null }{order.status === 'done' ?<i class="fas fa-check"></i>:null }</td>
                        <td>{order.payed === 'paid' ? <i class="fas fa-check"></i>: null} {order.payed != 'paid' ? <i class="fas fa-times"></i>: null}</td>
                        <td><Button>Download factuur</Button>  <Link to={{pathname: `/bezorgen/details`,state: {billData:order}}} className="table-link">Bekijk</Link></td>
                    </tr>
                ))}
                
                
            </table>
</Col>:null}     
{this.state.searchItem ?null:
                <Col sm={12} className="products-table">
                <h3>Nieuwe bestellingen</h3>
            <table>
                <tr>
                    <th>Order nr</th>
                    <th>Klant naam</th>
                    <th>Status</th>
                    <th>Betaald</th>
                    <th>Actie</th>
                </tr>

                {this.state.orders.filter((item)=> item.status != 'done').filter((item)=> item.shipment == '0' && item.newOrder ==true).map((order)=>(
                    <tr >
                        <td>{order.orderID}</td>
                        <td>{order.name}</td>
                        <td>{order.status === 'in progress' ?<i class="fas fa-ellipsis-h"></i>:null } {order.status === 'cancelled' ?<i class="fas fa-times"></i>:null }{order.status === 'done' ?<i class="fas fa-check"></i>:null }</td>
                        <td>{order.payed === 'paid' ? <i class="fas fa-check"></i>: null} {order.payed != 'paid' ? <i class="fas fa-times"></i>: null}</td>
                        <td> <Link to={{pathname: `/afhalen/details`,state: {billData:order}}} className="table-link">Bekijk</Link></td>
                    </tr>
                ))}
                
                
            </table>
</Col>}


   {this.state.searchItem ?null:
                <Col sm={12} className="products-table">
                <h3>Lopende bestellingen</h3>
            <table>
                <tr>
                    <th>Order nr</th>
                    <th>Klant naam</th>
                    <th>Status</th>
                    <th>Betaald</th>
                    <th>Actie</th>
                </tr>

                {this.state.orders.filter((item)=> item.status != 'done').filter((item)=> item.shipment == '0' && item.newOrder ==false).map((order)=>(
                    <tr >
                        <td>{order.orderID}</td>
                        <td>{order.name}</td>
                        <td>{order.status === 'in progress' ?<i class="fas fa-ellipsis-h"></i>:null } {order.status === 'cancelled' ?<i class="fas fa-times"></i>:null }{order.status === 'done' ?<i class="fas fa-check"></i>:null }</td>
                        <td>{order.payed === 'paid' ? <i class="fas fa-check"></i>: null} {order.payed != 'paid' ? <i class="fas fa-times"></i>: null}</td>
                        <td> <Link to={{pathname: `/afhalen/details`,state: {billData:order}}} className="table-link">Bekijk</Link></td>
                    </tr>
                ))}
                
                
            </table>
</Col>}
{this.state.searchItem ?null:
<Col sm={12} className="products-table">
                <h3>Afgeronde bestellingen</h3>
            <table>
                <tr>
                    <th>Order nr</th>
                    <th>Klant naam</th>
                    <th>Status</th>
                    <th>Betaald</th>
                    <th>Actie</th>
                </tr>

                {this.state.orders.sort(function(a, b){
                return a.orderID === b.orderID ? 0 : a.orderID < b.orderID ? -1 : 1;
            }).filter((item)=> item.status === 'done').filter((item)=> item.shipment == '0' ).map((order)=>(
                    <tr >
                        <td>{order.orderID}</td>
                        <td>{order.name}</td>
                        <td>{order.status === 'in progress' ?<i class="fas fa-ellipsis-h"></i>:null } {order.status === 'cancelled' ?<i class="fas fa-times"></i>:null }{order.status === 'done' ?<i class="fas fa-check"></i>:null }</td>
                        <td>{order.payed === 'paid' ? <i class="fas fa-check"></i>: null} {order.payed != 'paid' ? <i class="fas fa-times"></i>: null}</td>
                        <td> <Link to={{pathname: `/afhalen/details`,state: {billData:order}}} className="table-link">Bekijk</Link></td>
                    </tr>
                ))}
                
                
            </table>
</Col>}
   
            </Row>
            </div>



        );
    }
};
export default OrdersIndex;