import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import toast, { Toaster } from 'react-hot-toast';

const notify = () => toast('Je wijzigingen zijn opgeslagen!');

class ChangeProductsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id:'',
            productDesc:'',
            image:[],
            images:[],
            imagesNew:[],
            catsNew:[],
            tagsNew:[],
            uploading:false,
            uploaded:false,
            url:'',
            imageAvailable: false,
            activeVariations: false,
            cat:'',
            categories:[],
            catActive:false,
            tag:'',
            tags:[],
            tagActive:false,
            clientUUID:'-ohlnfwebk',
            name:'',
            city:'',
            stock:0,
            variant:'selected',
            color:false,
            weight:false,
            quantity:false,
            measure:false,
            type:false,
            addVariantText:'',
            addVariantPrice:'',
            addVariantStock:0,
            variations:[],
            changeVariantText:'',
            changeVariantPrice:'',
            changeVariantStock:0,
            changes: false,
            time:0,
            price:0,
            btwValue:'',
        };
    }

   

    changeProduct(){

      
        console.log(this.props.data.data.id)

        Firebase.database().ref('/clients/-3evre723ohfew/treatments/'+this.props.data.data.id).update({
            gender:this.state.status,
            price:parseFloat(this.state.price),
            time:Number(this.state.time),
        title:this.state.name,
        btwValue:this.state.btwValue,
        
        }).then(()=>{
            toast.success('Wijzigingen opgeslagen')
            this.setState({
                changes:false
            })
        })

   

    }

    

    componentWillMount() {
        

        this.setState({
            name:this.props.data.data.title,
            id:this.props.data.data.id,
            productDesc:this.props.data.data.productDesc,
            price:this.props.data.data.price,
            stock:this.props.data.data.stock,

            btwValue:this.props.data.data.btwValue,
            activeVariations: this.props.data.data.activeVariations,
            //variations: this.props.data.data.activeVariations ?this.props.data.data.variations :[],
            variant: this.props.data.data.activeVariations?this.props.data.data.variant:'selected',
            status:this.props.data.data.gender,
            time:this.props.data.data.time
        })

        

   
       
    }

      

    

    render() {
        return (
            <div>
                <Toaster />
            <Row className="col-sm-12">
                <Col sm={12} className="products-actions">
                    <Link to={{pathname: `/producten`}}>Terug</Link>
                    <Button className="main-save-button" onClick={()=>this.changeProduct()}>Opslaan</Button>
                    {/*<Link className="align-button-right">Product toevoegen</Link>*/}
                </Col>
                <Col sm={8} className="colum-left">
                    <Col sm={4} className="mobile">
                    <Row>
                        <Col sm={12} className="add-product-colum right-product-add-colom mobile-last">
                            <label>Gender</label><br/>
                            <select value={this.state.status} onChange={(event)=>this.setState({status: event.target.value,changes:true})}>
                            <option value="selected" selected="selected" disabled="disabled">Selecteer een Gender</option>
                                <option value="Dames">Dames</option>
                                <option value="Heren">Heren</option>
                                <option value="Kind">Kind</option>
                            </select>
                            
                        </Col>
                        
                    </Row>
                </Col>
                    <Row>
                        <Col sm={12} className="add-product-colum">
                            <label>Productnaam</label><br/>
                            <input type="text" value={this.state.name} onChange={(event)=>this.setState({name: event.target.value,changes:true})}/><br/><br/>
                           {/*} <label>Product omschrijving</label><br/>
                            <CKEditor
                                editor={ ClassicEditor }
                                data={this.state.productDesc}
                                
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    this.setState({productDesc: data,changes:true})
                                } }

                            />*/}
                            
                        </Col>
                       {/*} <Col sm={12} className="add-product-colum">
                            <label>Media</label><br/>
                            <input type="file" accept="image/gif, image/jpeg, image/png" onChange={(event) => this.handleChange(event)} /><Button className="add-button-settings" onClick={()=>this.handleUpload()}>Voeg afbeelding toe</Button> <br/><br/>
                            <label>Geselecteerde product foto's</label>
                            {this.state.imageAvailable ?
                                <Row>
                                {this.state.images.map((image)=>(
                                <Col sm={4} className="product-images">
                                    <span className="delete-image button-list button-onimage" >
                                        <Button onClick={()=>this.deleteImage(image.id)}>Verwijder</Button>
                                    </span>
                                    <img src={image.url}/>
                                </Col>
                                ))}
                            </Row>
                            :
                            <Row>
                                <Col sm={12} className="nothing-present">
                                    <span>Geen afbeeldingen geselecteert</span>
                                </Col>
                            </Row>
                            }
                           
                        </Col>*/}
                       <Col sm={12} className="add-product-colum">
                            <label>Prijs per stuk incl. BTW</label><br/>
                            <span className="input-price">€ <input type="text" value={this.state.price} onChange={(event)=>this.setState({price: event.target.value,changes:true})}/> </span><br/><br/>
                            <label>Tijd in minuten</label><br/>
                            <span className="input-price"> <input type="text" value={this.state.time} onChange={(event)=>this.setState({time: event.target.value,changes:true})}/> </span><br/><br/>
                            <label>BTW</label><br/>
                            <select value={this.state.btwValue} onChange={(event)=>this.setState({btwValue: event.target.value,changes:true})}>
                            <option value="selected" selected="selected" disabled="disabled">Selecteer een BTW</option>
                                <option value="9">9%</option>
                                <option value="21">21%</option>
                            </select>
                            
                            
                            
                            </Col>
                        <Col sm={12} className="last-col add-product-colum">
                        < hr/>
                        <Button onClick={()=>this.changeProduct()}>Opslaan</Button>
                        </Col>

                    </Row>
                </Col>
            
                <Col sm={4} className="dekstop">
                    <Row>
                        <Col sm={12} className="add-product-colum right-product-add-colom mobile-last">
                            <label>Gender</label><br/>
                            <select value={this.state.status} onChange={(event)=>this.setState({status: event.target.value,changes:true})}>
                            <option value="selected" selected="selected" disabled="disabled">Selecteer een Gender</option>
                                <option value="Dames">Dames</option>
                                <option value="Heren">Heren</option>
                                <option value="Kind">Kind</option>
                            </select>
                            
                        </Col>
                       
                    </Row>
                </Col>
   
            </Row>
            {this.state.changes?
            <Row className="changed-row">
                <Col sm={12} className="changed-col">
                    <p>Er zijn wijzigingen aangebracht. Vergeet niet om het product op te slaan.</p>
                    <Button  className="changed-button" onClick={()=>this.changeProduct()}>Opslaan</Button>
                </Col>
            </Row>:null}
            </div>



        );
    }
};

export default ChangeProductsComponent;