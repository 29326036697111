import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { StaticRouter } from 'react-router';
import { set } from 'react-ga';
import { Satellite } from '@material-ui/icons';


class ProductsOverviewComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            products:[],
            categories:[],
            selectedProduct:[],
            isProductSelected:false,
            clientUUID:'-ohlnfwebk',
            city:''
        };
    }

    getProducts(){
    
        let ref = Firebase.database().ref('/clients/-3evre723ohfew/treatments');
        ref.on('value' , snapshot => {
            this.setState({products:[]})
           
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();
 
             this.setState(prevState => ({
                        products: [...prevState.products, state]
                    })) 
        })
    });
    }





    componentDidMount() {


      this.getProducts();
     


      

        
    }

    changeActive(product){

        for(let k = 0; k <this.state.products.length; k++){
                this.state.products[k].checked = false;
                this.state.products[k].active = 'not-active';
                
                this.setState({
                    products: this.state.products,
                    selectedProduct: [],
                    isProductSelected:false
                })
               
             
        }

        for(let i = 0; i < this.state.products.length; i++){
            if(this.state.products[i].id === product.id && this.state.products[i].checked === false){
                this.state.products[i].active = 'active';
                this.state.products[i].checked = true;
                this.setState({
                    products: this.state.products,
                    selectedProduct: this.state.products[i],
                    isProductSelected:true
                })
            }else if(this.state.products[i].id === product.id && this.state.products[i].checked === true){
                this.state.products[i].active = 'not-active';
                this.state.products[i].checked = false;
                this.setState({
                    products: this.state.products,
                })
            }
        }


      
     
        
       /* this.setState({
            products:this.state.products.filter(item => item.id !== product.id),
        })

        let newProduct = {id:product.id,name:product.name, status:product.status,stock: product.stock,category:product.category,active:'active'}
        this.setState(prevState =>({
            products: [...prevState.products,newProduct]
        }))*/
    }


    deleteProduct(){
        Firebase.database().ref('/clients/-3evre723ohfew/treatments/'+this.state.selectedProduct.id).remove();
        this.setState({
            products:this.state.products.filter(item => item.id !== this.state.selectedProduct.id),
            selectedProduct:[],
            isProductSelected:false
        });

        
    }

    

    render() {
        return (
            <div>
            <Row className="col-sm-12">
            <Col sm={12}>
        <input type="text" placeholder="Zoeken..." id="icon" className="search-bar" value={this.state.searchItem} onChange={(event)=>this.setState({searchItem:event.target.value})} />
        </Col>
                <Col sm={12} className="products-actions">
                    {this.state.isProductSelected ?
                    <div>
                    <Link to={{pathname: `/producten/aanpassen`,state: {data: this.state.selectedProduct}}} >Product bewerken</Link>
                    <Button className="delete-button" onClick={() => this.deleteProduct()}>Verwijder product</Button></div>:null}
                    <Link className="align-button-right" to={{pathname: `/producten/toevoegen`}} >Product toevoegen</Link>
                </Col>
             
        {this.state.searchItem ?
        <Col sm={12} className="products-table">
                <h3>Zoek resultaten</h3>
                <table>
                <tr>
                    <th><input type="checkbox" disabled/></th>
                    <th>Gender</th>
                    <th>Naam</th>
                    <th>BTW</th>
                    <th>Prijs</th>
                </tr>

                {this.state.products.filter((item) => this.state.searchItem ?  item.title.toLowerCase().includes(this.state.searchItem.toLowerCase()):item.title == item.title).map((product)=>(
                    <tr className={product.active}>
                    <td><input type="checkbox" checked={product.checked} onClick={()=>this.changeActive(product)}/></td>
                    <td>{product.gender}</td>
                    <td>{product.title}</td>
                    <td>{product.btw}%</td>
                    <td>€ {parseFloat(product.price).toFixed(2)}</td>
                </tr>
                ))}
                
            
            </table>
</Col>:null} 
{this.state.searchItem ? null :
                <Col sm={12} className="products-table">
                       <h3>Alle producten</h3>
            <table>
                <tr>
                    <th><input type="checkbox" disabled/></th>
                    <th>Gender</th>
                    <th>Naam</th>
                    <th>BTW</th>
                    <th>Prijs</th>
                </tr>

                {this.state.products.map((product)=>(
                     <tr className={product.active}>
                     <td><input type="checkbox" checked={product.checked} onClick={()=>this.changeActive(product)}/></td>
                     <td>{product.gender}</td>
                     <td>{product.title}</td>
                     <td>{product.btw}%</td>
                    <td>€ {parseFloat(product.price).toFixed(2)}</td>
                </tr>
                ))}
                
            
            </table>
</Col>}
   
            </Row>
            </div>



        );
    }
};

export default ProductsOverviewComponent;