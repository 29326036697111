import React from "react";
import Calendar from "react-calendar";
import { Container, Row, Col } from "shards-react";
import CalenderComponent from "../../components/calendar/CalenderComponent";

import PageTitle from "../../components/common/PageTitle";
import OrdersIndex from "../../components/orders";
import MobileNavigation from "../../layouts/mobileNavigation";



const CalendarView = () => (
  <Container fluid className="main-content-container px-4">
    <MobileNavigation />
    <Row noGutters className="page-header py-4">
      <PageTitle title="Agenda" subtitle="Vanity Haircare" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>
    <CalenderComponent />
    
  </Container>
);

export default CalendarView;
